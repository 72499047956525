import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import App from './app';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <StrictMode>
    <App tab="home" />
  </StrictMode>,
);
